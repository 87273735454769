<template>
	<el-tabs :before-leave="go_tab">
		<el-tab-pane lazy label="监测点历史数据">
			<madmin2 style="margin-bottom:10px" ref="main1" title="用电数据管理" table_name="Device_data" :table_show_header="true">
				<!-- 弹出编辑模态窗口 -->
				<template #writeForm="{writeForm}">

					<el-row>
						<el-form-item prop="current_a" label="A相电流">
							<el-input clearable v-model="writeForm.current_a" placeholder="" />
						</el-form-item>
						<el-form-item prop="voltage_a" label="A相电压">
							<el-input clearable v-model="writeForm.voltage_a" placeholder="" />
						</el-form-item>
					</el-row>
					<el-row>
						<el-form-item prop="current_b" label="B相电流">
							<el-input clearable v-model="writeForm.current_b" placeholder="" />
						</el-form-item>
						<el-form-item prop="voltage_b" label="B相电压">
							<el-input clearable v-model="writeForm.voltage_b" placeholder="" />
						</el-form-item>
					</el-row>
					<el-row>
						<el-form-item prop="current_c" label="C相电流">
							<el-input clearable v-model="writeForm.current_c" placeholder="" />
						</el-form-item>
						<el-form-item prop="voltage_c" label="C相电压">
							<el-input clearable v-model="writeForm.voltage_c" placeholder="" />
						</el-form-item>
					</el-row>
					<el-row>
						<el-form-item prop="power" label="功率">
							<el-input clearable v-model="writeForm.power" placeholder="" />
						</el-form-item>
						<el-form-item prop="quantity" label="电量">
							<el-input clearable v-model="writeForm.quantity" placeholder="" />
						</el-form-item>
					</el-row>
					<el-row>
						<el-form-item prop="create_time" label="采集时间">
							<el-date-picker format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" v-model="writeForm.create_time" type="datetime" placeholder="选择日期时间"></el-date-picker>
						</el-form-item>
					</el-row>
				</template>
				<!-- 搜索框 -->
				<template #searchForm="{searchForm,show_more_search}">
					<el-form-item label="接入企业名称" prop="company_name" v-if="!is_company">
						<el-input v-model="searchForm.company_name" />
					</el-form-item>
					<el-form-item label="所在回路" prop="tag" v-show="show_more_search">
						<el-input v-model="searchForm.tag" />
					</el-form-item>
					<el-form-item label="监测点名称" prop="name" v-show="show_more_search">
						<el-input v-model="searchForm.name" />
					</el-form-item>
					<el-form-item label="采集时间" prop="create_time">
						<el-date-picker v-model="searchForm.create_time" type="daterange" :picker-options="time_opt" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd" align="right"></el-date-picker>
					</el-form-item>
				</template>
				<!-- 列表 -->
				<template #table>
					<el-table-column label="采集地点">
						<el-table-column label="接入企业" prop="company_name" align="center" />
						<el-table-column label="所在回路" prop="tag" align="center" />
						<el-table-column label="监测点" prop="name" align="center" />
					</el-table-column>
					<el-table-column label="采集时间" width="120px" align="center" prop="create_time" />
					<el-table-column label="电量kWh" width="100px" align="center" prop="quantity" />
					<el-table-column label="功率kW" width="80px" align="center" prop="power" />
					<el-table-column label="电流(A)">
						<el-table-column label="A相" prop="current_a" align="center" width="70px" />
						<el-table-column label="B相" prop="current_b" align="center" width="70px" />
						<el-table-column label="C相" prop="current_c" align="center" width="70px" />
					</el-table-column>
					<el-table-column label="电压(V)">
						<el-table-column label="A相" prop="voltage_a" align="center" width="70px" />
						<el-table-column label="B相" prop="voltage_b" align="center" width="70px" />
						<el-table-column label="C相" prop="voltage_c" align="center" width="70px" />
					</el-table-column>

					<el-table-column label="操作" align="center" width="120" v-if="role!='普通管理员'">
						<template slot-scope="scope">
							<el-link style="margin-right:10px" icon="el-icon-delete" type="primary" @click="del_btn('main1', scope.row.id)">删除</el-link>
							<!-- <el-link icon="el-icon-edit" type="primary" @click="edit_btn('main1', scope.row.id)">编辑</el-link> -->
						</template>
					</el-table-column>
				</template>
				<!-- 侧边栏 -->
				<template #sider>
				</template>
			</madmin2>
		</el-tab-pane>
		<el-tab-pane lazy label="监测点最新数据(实时)">
			<madmin1 style="margin-bottom:10px" ref="main0" title="用电数据管理" table_name="stat/Device_data_now" :table_show_header="true">
				<!-- 弹出编辑模态窗口 -->
				<!-- 搜索框 -->
				<template #searchForm="{searchForm,show_more_search}">
					<el-form-item label="接入企业名称" prop="company_name" v-if="!is_company">
						<el-input v-model="searchForm.company_name" />
					</el-form-item>
					<el-form-item label="所在回路" prop="tag" v-show="show_more_search">
						<el-input v-model="searchForm.tag" />
					</el-form-item>
					<el-form-item label="监测点名称" prop="name" v-show="show_more_search">
						<el-input v-model="searchForm.name" />
					</el-form-item>
					<el-form-item label="采集时间" prop="create_time">
						<el-date-picker v-model="searchForm.create_time" type="daterange" :picker-options="time_opt" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd" align="right"></el-date-picker>
					</el-form-item>
				</template>
				<!-- 列表 -->
				<template #table>
					<el-table-column label="采集地点">
						<el-table-column label="接入企业" prop="company_name" align="center" />
						<el-table-column label="所在回路" prop="tag" align="center" />
						<el-table-column label="监测点" prop="name" align="center" />
					</el-table-column>
					<el-table-column label="采集时间" width="120px" align="center" prop="create_time" />
					<el-table-column label="电量kWh" width="100px" align="center" prop="quantity" />
					<el-table-column label="功率kW" width="80px" align="center" prop="power" />
					<el-table-column label="电流(A)">
						<el-table-column label="A相" prop="current_a" align="center" width="70px" />
						<el-table-column label="B相" prop="current_b" align="center" width="70px" />
						<el-table-column label="C相" prop="current_c" align="center" width="70px" />
					</el-table-column>
					<el-table-column label="电压(V)">
						<el-table-column label="A相" prop="voltage_a" align="center" width="70px" />
						<el-table-column label="B相" prop="voltage_b" align="center" width="70px" />
						<el-table-column label="C相" prop="voltage_c" align="center" width="70px" />
					</el-table-column>
					<!-- <el-table-column label="电流" width="120px" align="right">
						<template slot-scope="scope">
							<span style="float:left">A相</span> {{scope.row.current_a}}A<br />
							<span style="float:left">B相</span> {{scope.row.current_b}}A<br />
							<span style="float:left">C相</span> {{scope.row.current_c}}A
						</template>
					</el-table-column>
					<el-table-column label="电压" width="120px" align="right">
						<template slot-scope="scope">
							<span style="float:left">A相</span> {{scope.row.voltage_a}}V<br />
							<span style="float:left">B相</span> {{scope.row.voltage_b}}V<br />
							<span style="float:left">C相</span> {{scope.row.voltage_c}}V
						</template>
					</el-table-column> -->
					<!-- <el-table-column label="操作" align="center" width="120px">
						<template slot-scope="scope">
							<el-link style="margin-right:10px" icon="el-icon-delete" type="primary" @click="del_btn('main1', scope.row.id)">删除</el-link>
							<el-link icon="el-icon-edit" type="primary" @click="edit_btn('main1', scope.row.id)">编辑</el-link>
						</template>
					</el-table-column> -->
				</template>
				<!-- 侧边栏 -->
				<template #sider>
				</template>
			</madmin1>
		</el-tab-pane>
		
	</el-tabs>
</template>
<script>
	import madmin1 from "../../components/madmin1.vue";
	import madmin2 from "../../components/madmin2.vue";
	import muploadAvatar from "../../components/form/muploadAvatar.vue";
	import mechart from "../../components/mechart.vue";
	import request from "../../plugins/axios";

	export default {
		data() {
			return {
				is_company:
					window.localStorage.getItem("role") == "企业管理员" ? true : false,
				role: window.localStorage.getItem("role"),
				//datapicker的快速选择
				time_opt: {
					shortcuts: [
						{
							text: "最近一周",
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
								picker.$emit("pick", [start, end]);
							},
						},
						{
							text: "最近一个月",
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
								picker.$emit("pick", [start, end]);
							},
						},
						{
							text: "最近三个月",
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
								picker.$emit("pick", [start, end]);
							},
						},
					],
				},
				select_company: [],
			};
		},
		components: {
			madmin1,
			madmin2,
			mechart,
			muploadAvatar,
		},
		mounted() {
			this.get_select_company();
		},
		methods: {
			search_btn(ref_name) {
				this.$refs[ref_name].onSearch();
			},
			del_btn(ref_name, id) {
				this.$refs[ref_name].onDelete(id);
			},
			edit_btn(ref_name, id) {
				this.$refs[ref_name].showWriteForm(id);
			},
			go_tab(activeName, oldActiveName) {},
			get_select_company() {
				request({
					url: "/admin/Company/select_list",
					data: {
						token: window.localStorage.getItem("token"),
					},
				}).then((ret) => {
					if (ret.code == 1) {
						this.select_company = ret.data;
					}
				});
			},
		},
	};
</script>